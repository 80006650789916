import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import "./css/main.css";
import { rootStore } from "./AppStore/AppStoreRegistryStore";
import MainApp from "./MainApp";
import "@dnb/eufemia/style";

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <Provider store={rootStore}>
      <MainApp />
    </Provider>
  </BrowserRouter>,
  // <React.StrictMode>
  //   <Provider store={rootStore}>
  //     <MainApp />
  //   </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
