import { OwnershipV1 } from "../_EnterpriseCommon.model";
import {
  commonService_Update_IdentificationMethod,
  commonService_UpdateChanges_LastAssessment,
} from "./EnterpriseCommon.service";

export const ownershipService_updateIdentification = (
  status: OwnershipV1,
  payload: { choices: string[] | undefined; comment: string | undefined },
): OwnershipV1 => {
  let item = commonService_Update_IdentificationMethod(
    status.identificationMethod,
    payload,
  );
  let result: OwnershipV1;
  result = {
    ...status,
    identificationMethod: item,
  };
  return result;
};

export const ownershipService_updateChanges = (
  status: OwnershipV1,
  payload: { choice: string; comment: string | undefined },
): OwnershipV1 => {
  let changes = commonService_UpdateChanges_LastAssessment(
    status.changes,
    payload,
  );
  let result: OwnershipV1;
  result = {
    ...status,
    changes: changes,
  };
  return result;
};

export const ownershipService_updateComment = (
  status: OwnershipV1,
  payload: string | undefined,
): OwnershipV1 => {
  let result: OwnershipV1;
  result = {
    ...status,
    comment: payload,
  };
  return result;
};
