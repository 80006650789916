import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { RootState } from "./AppStoreRegistryStore";
import { arrayIsEmpty, arrayIsNotEmpty } from "../Utils/ArrayUtils";
import {
  UserSignatureStateV1,
  UserSignatureV1,
} from "../_domain-model/UserSignatureV1.model";

const initialState: UserSignatureStateV1 = {
  userHasSigned: undefined,
  signatures: undefined,
};

const init = (
  state: WritableDraft<UserSignatureStateV1>,
  payload: string | null | undefined,
) => {
  state.userHasSigned = false;
  state.signatures = [];
};

const initUserSignaturesFromState = (
  state: WritableDraft<UserSignatureStateV1>,
  payload: UserSignatureV1[] | null | undefined,
) => {
  if (arrayIsNotEmpty(payload)) {
    state.signatures = payload;
  } else {
    state.signatures = [];
  }
};

const setUserSigned = (
  state: WritableDraft<UserSignatureStateV1>,
  payload: boolean | null | undefined,
) => {
  if (!payload) {
    state.userHasSigned = false;
    return;
  }

  state.userHasSigned = payload;
};

const removeSignature = (
  state: WritableDraft<UserSignatureStateV1>,
  payload: string | null | undefined,
) => {
  if (arrayIsEmpty(state.signatures)) {
    return;
  }

  const filtered = state.signatures.filter((x) => x.who !== payload);
  state.signatures = [...filtered];
};

const addSignature = (
  state: WritableDraft<UserSignatureStateV1>,
  payload: UserSignatureV1 | null | undefined,
) => {
  if (!payload) {
    return;
  }

  let added = false;
  if (arrayIsNotEmpty(state.signatures)) {
    var existing = state.signatures.filter((x) => x.who === payload.who);
    if (arrayIsNotEmpty(existing)) {
      added = true;
    }
  }

  if (!added) {
    let signatures = [...state.signatures];
    signatures.unshift(payload);
    state.signatures = signatures;
  }
};

export const UserSignatureStore = createSlice({
  name: "UserSignatureStore",
  initialState,
  reducers: {
    initUserSignaturesAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      init(state, action.payload);
    },
    initUserSignaturesFromStateAction: (
      state,
      action: PayloadAction<UserSignatureV1[] | null | undefined>,
    ) => {
      initUserSignaturesFromState(state, action.payload);
    },
    setUserSignedAction: (
      state,
      action: PayloadAction<boolean | null | undefined>,
    ) => {
      setUserSigned(state, action.payload);
    },
    removeSignatureAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      removeSignature(state, action.payload);
    },
    addSignatureAction: (
      state,
      action: PayloadAction<UserSignatureV1 | null | undefined>,
    ) => {
      addSignature(state, action.payload);
    },
  },
});

export const {
  initUserSignaturesAction,
  initUserSignaturesFromStateAction,
  setUserSignedAction,
  removeSignatureAction,
  addSignatureAction,
} = UserSignatureStore.actions;

export const selectCurrentUserSignedState = (state: RootState) =>
  state.UserSignatureStore?.userHasSigned;
export const selectUserSignaturesState = (state: RootState) =>
  state.UserSignatureStore?.signatures;

export default UserSignatureStore.reducer;
