import { OriginOfWealthV1, PurposeV1 } from "../_EnterpriseCommon.model";
import {
  commonService_Update_IdentificationMethod,
  commonService_UpdateChanges_LastAssessment,
  commonService_UpdateRisk_LastAssessment,
} from "./EnterpriseCommon.service";

export const purposeService_updateIdentification = (
  status: PurposeV1,
  payload: { choices: string[]; comment: string | undefined },
): PurposeV1 => {
  let item = commonService_Update_IdentificationMethod(
    status.identificationMethod,
    payload,
  );
  let result: PurposeV1;
  result = {
    ...status,
    identificationMethod: item,
  };
  return result;
};

export const purposeService_updateRisk = (
  status: PurposeV1,
  payload: { choice: string; comment: string | undefined },
): PurposeV1 => {
  let changes = commonService_UpdateRisk_LastAssessment(
    status.changes,
    payload,
  );
  let result: PurposeV1;
  result = {
    ...status,
    changes: changes,
  };
  return result;
};

export const purposeService_updateChanges = (
  status: PurposeV1,
  payload: { choice: string; comment: string | undefined },
): PurposeV1 => {
  let changes = commonService_UpdateChanges_LastAssessment(
    status.changes,
    payload,
  );
  let result: PurposeV1;
  result = {
    ...status,
    changes: changes,
  };
  return result;
};

export const purposeService_updateComment = (
  status: PurposeV1,
  payload: string | undefined,
): PurposeV1 => {
  let result: PurposeV1;
  result = {
    ...status,
    comment: payload,
  };
  return result;
};

export const purposeService_updateTpaComment = (
  status: PurposeV1,
  payload: string | undefined,
): PurposeV1 => {
  let result: PurposeV1;
  result = {
    ...status,
    tpaComment: payload,
  };
  return result;
};
export const purposeService_update = (
  status: PurposeV1,
  payload: string | undefined,
): PurposeV1 => {
  let result: PurposeV1;
  result = {
    ...status,
    tpaComment: payload,
  };
  return result;
};

export const purposeService_updateCustomerPurposeRelationshipComment = (
  status: PurposeV1,
  payload: {
    customerPurposeComment: string | undefined;
  },
): PurposeV1 => {
  let result: PurposeV1;
  result = {
    ...status,
    customerPurposeComment: payload.customerPurposeComment,
  };
  return result;
};

export const purposeService_updateOriginWealthsComment = (
  status: OriginOfWealthV1,
  payload: string | undefined,
): OriginOfWealthV1 => {
  const result: OriginOfWealthV1 = {
    ...status,
    comment: payload,
  };

  return result;
};
