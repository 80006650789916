import { configureStore } from "@reduxjs/toolkit";
import AppConfigurationStore from "./AppConfigurationStore";
import CustomerAssessmentStore from "./CustomerAssessmentStore";
import CustomerSearchStore from "./CustomerSearchStore";
import UserSettingStore from "./UserSettingStore";
import UserCommentStore from "./UserCommentStore";
import UserImageStore from "./UserImageStore";
import UserSignatureStore from "./UserSignatureStore";
import CopyAssessmentSessionStore from "./CopyAssessmentSessionStore";
import OperationStore from "./OperationStore";

export const rootStore = configureStore({
  reducer: {
    AppConfigurationStore: AppConfigurationStore,
    UserSettingStore: UserSettingStore,
    CustomerSearchStore: CustomerSearchStore,
    CustomerAssessmentStore: CustomerAssessmentStore,
    UserCommentStore: UserCommentStore,
    UserImageStore: UserImageStore,
    UserSignatureStore: UserSignatureStore,
    CopyAssessmentSessionStore: CopyAssessmentSessionStore,
    OperationStore: OperationStore,
  },
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
