import { BeneficiaryV1 } from "../_EnterpriseCommon.model";

export const beneficiaryService_updateComment = (
  status: BeneficiaryV1,
  payload: string | undefined,
): BeneficiaryV1 => {
  let result: BeneficiaryV1;
  result = {
    ...status,
    comment: payload,
  };
  return result;
};
