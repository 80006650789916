import { RepresentativesV1 } from "../_EnterpriseCommon.model";
import {
  commonService_Update_IdentificationMethod,
  commonService_UpdateChanges_LastAssessment,
  commonService_UpdateRisk_LastAssessment,
} from "./EnterpriseCommon.service";

export const representativesService_updateChanges = (
  status: RepresentativesV1,
  payload: { choice: string; comment: string | undefined },
): RepresentativesV1 => {
  let changes = commonService_UpdateChanges_LastAssessment(
    status.changes,
    payload,
  );
  let result: RepresentativesV1;
  result = {
    ...status,
    changes: changes,
  };
  return result;
};

export const representativesService_updateRisk = (
  status: RepresentativesV1,
  payload: { choice: string; comment: string | undefined },
): RepresentativesV1 => {
  let changes = commonService_UpdateRisk_LastAssessment(
    status.changes,
    payload,
  );
  let result: RepresentativesV1;
  result = {
    ...status,
    changes: changes,
  };
  return result;
};

export const representativesService_updateIdentification = (
  status: RepresentativesV1,
  payload: { choices: string[]; comment: string | undefined },
): RepresentativesV1 => {
  let item = commonService_Update_IdentificationMethod(
    status.identificationMethod,
    payload,
  );
  let result: RepresentativesV1;
  result = {
    ...status,
    identificationMethod: item,
  };
  return result;
};
