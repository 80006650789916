export const arrayIsEmpty = (items: any[] | undefined | null): boolean => {
  if (!items || items === null || items.length === 0) {
    return true;
  }
  return false;
};
export const arrayIsNotEmpty = (items: any[] | undefined | null): boolean => {
  const empty = arrayIsEmpty(items);
  return empty === true ? false : true;
};

export const arrayIsItemSelected = (
  items: string[] | undefined | null,
  item: string,
): boolean => {
  const index = arrayFindIndex(items, item);
  return index >= 0;
};

export const arrayFindIndex = (
  items: string[] | undefined | null,
  item: string,
) => {
  let index: number;
  index = -1;

  const empty = arrayIsEmpty(items);
  if (empty) {
    return index;
  }

  items.forEach((obj, idx) => {
    if (obj === item) {
      index = idx;
    }
  });

  return index;
};

export const arrayToggleItem = (items: string[], item: string): string[] => {
  const index = arrayFindIndex(items, item);
  const copy = [...items];

  if (index >= 0) {
    copy.splice(index, 1);
  } else {
    copy.push(item);
  }
  return [...copy];
};
