import { OPTION_NO, OPTION_YES } from "../_models/_Constants";

export const stringToTitleCase = (
  str: string | null | undefined,
): string | null => {
  if (str) {
    return str
      .split(" ")
      .map((w) => (w ? w[0].toUpperCase() + w.substr(1).toLowerCase() : w))
      .join(" ");
  } else {
    return null;
  }
};

export const getCleanedOrgNo = (value: string): string => {
  if (!value || value == null || value === "") {
    return "";
  }
  let s = value.replace(/[^0-9\S]/g, "");
  if (!s || s === "") {
    return s;
  }

  if (s.length === 11 && s.slice(0, 2) === "00") {
    s = s.substring(2);
  }

  return s;
};

export const validateOrgNo = (value: string): boolean => {
  return value.length === 9 && /\d/.test(value);
};

export const stringValidateSsnOrgNr = (
  input: string | undefined | null,
): { value: string; valid: boolean; formatted?: string | undefined } => {
  if (!input || input == null || input === "") {
    return { value: input, valid: false };
  }

  let value = input.trim().replace(/\D+/g, "");

  if (/^\d+$/.test(value)) {
    let s: string;
    if (value.length === 9 || value.length === 11) {
      if (value.length === 9) {
        s = value.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
      } else {
        s = value.replace(/(\d{5})(\d{6})/, "$1 $2");
      }
      return { value: value, valid: true, formatted: s };
    }
  }

  return { value: value, valid: false };
};

export const stringValidateYesNoOption = (
  input: string | undefined | null,
): string => {
  if (!input || input == null) {
    return undefined;
  }

  if (input === OPTION_YES || input === OPTION_NO) {
    return input;
  }

  return undefined;
};

export const stringToBoolean = (input: string | undefined | null): boolean => {
  if (!input || input == null) {
    return undefined;
  }

  if (input.toLowerCase() === OPTION_YES) {
    return true;
  }

  return false;
};

export const stringIsNotEmpty = (input: string | undefined | null): boolean => {
  if (stringIsEmpty(input) === true) {
    return false;
  }

  return true;
};

export const stringIsEmpty = (input: string | undefined | null): boolean => {
  if (input === undefined || input == null) {
    return true;
  }

  input = input.trim();
  if (input === "") {
    return true;
  }

  return false;
};

export const stringSplitMultiLine = (
  input: string | undefined | null,
): string[] | undefined => {
  if (stringIsEmpty(input)) {
    return undefined;
  }

  return input.split("\n");
};
