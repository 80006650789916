import { ExtraInfoV1 } from "../_EnterpriseCommon.model";
import { commonService_Update_ChoiceWithComment } from "./EnterpriseCommon.service";

export const extraInfoService_updateChoiceComment = (
  status: ExtraInfoV1,
  payload: { choice: string; comment: string | undefined },
): ExtraInfoV1 => {
  let info = commonService_Update_ChoiceWithComment(status?.info, payload);
  let result: ExtraInfoV1;
  result = {
    ...status,
    info: info,
  };
  return result;
};

export const extraInfoService_updateTiltakComment = (
  status: ExtraInfoV1,
  payload: { choice: string; comment: string | undefined },
): ExtraInfoV1 => {
  let tiltak = commonService_Update_ChoiceWithComment(status?.tiltak, payload);
  let result: ExtraInfoV1;
  result = {
    ...status,
    tiltak: tiltak,
  };
  return result;
};

export const extraInfoService_updateCanEstablish = (
  status: ExtraInfoV1,
  payload: { choice: string; comment: string | undefined },
): ExtraInfoV1 => {
  let tiltak = commonService_Update_ChoiceWithComment(
    status?.canEstablish,
    payload,
  );
  let result: ExtraInfoV1;
  result = {
    ...status,
    canEstablish: tiltak,
  };
  return result;
};

export const extraInfoService_updateComment = (
  status: ExtraInfoV1,
  payload: string | undefined,
): ExtraInfoV1 => {
  let result: ExtraInfoV1;

  result = {
    ...status,
    comment: payload,
  };
  return result;
};
