import { CustomerInfoV1 } from "../_EnterpriseCommon.model";
import {
  commonService_Update_IdentificationMethod,
  commonService_UpdateChanges_LastAssessment,
  commonService_UpdateRisk_LastAssessment,
} from "./EnterpriseCommon.service";

export const customerInfoService_updateIdentification = (
  status: CustomerInfoV1,
  payload: { choices: string[]; comment: string | undefined },
): CustomerInfoV1 => {
  let item = commonService_Update_IdentificationMethod(
    status.identificationMethod,
    payload,
  );
  let result: CustomerInfoV1;
  result = {
    ...status,
    identificationMethod: item,
  };
  return result;
};
export const customerInfoService_updateChanges = (
  status: CustomerInfoV1,
  payload: { choice: string; comment: string | undefined },
): CustomerInfoV1 => {
  let item = commonService_UpdateChanges_LastAssessment(
    status.changes,
    payload,
  );
  let result: CustomerInfoV1;
  result = {
    ...status,
    changes: item,
  };
  return result;
};
export const customerInfoService_updateRisk = (
  status: CustomerInfoV1,
  payload: { choice: string; comment: string | undefined },
): CustomerInfoV1 => {
  let item = commonService_UpdateRisk_LastAssessment(status.changes, payload);
  let result: CustomerInfoV1;
  result = {
    ...status,
    changes: item,
  };
  return result;
};
