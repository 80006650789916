export const DEPLOY_MODES = {
  DEPLOYED: "deployed",
  LOCAL: "local",
  MOCK: "mock",
};

export const OPERATION_STATE = {
  NOT_IN_PROGRESS: 1,
  IN_PROGRESS: 2,
  COMPLETED_SUCCESS: 200,
  COMPLETED_ERROR: 400,
};

export const DOC_EXPROT = {
  GREEN: "#007272",
  RED: "#dc2a2a",
  P_WIDTH: "550px",
  H1: "26px",
  H2: "24px",
  H3: "22px",
};

export const PDF_EXPORT = {
  GREEN: "#007272",
  RED: "#dc2a2a",
  P_WIDTH: 500,
  LIST_WIDTH: 400,
  H1: 24,
  H2: 20,
  H3: 18,
  H4: 16,
  QUESTION: 14,
  NORMAL: 12,
  SMALL: 8,
  FONT_NORMAL: "Helvetica",
  FONT_BOLD: "Helvetica-Bold",
  FONT_ITALIC: "Helvetica-Oblique",
};

export const AUTO_SAVE_INTERVAL = 5 * 60 * 1000;

export const MAX_ALLOWED_SIGNATURES = 3;

export const SEARCH_STATES = {
  NOT_SEARCHED: 10,
  VALID: 20,
  NOT_VALID: 30,
};

export const LANGUAGES = {
  NORWEGIAN_BOKMAAL: "nb",
  ENGLISH: "en",
};

export const OPTION_OTHER = "other";
export const OPTION_YES = "yes";
export const OPTION_NO = "no";
export const PERSON_ROLES = {
  DAGLIGLEDER: "Daglig leder",
};

export const TRANSACTION_ALARMS = {
  TRANSAKSJONSALARM: "Transaksjonsalarm",
  FT011_SC007: "FT011 - SC007",
  FT011_SC010: "FT011 - SC010",
  FT012_SC004: "FT012 - SC004",
  FT013_SC001: "FT013 - SC001",
  FT013_SC002: "FT013 - SC002",
  FT015: "FT015",
  FT016: "FT016",
};

export const TRANSACTION_FIELDS = {
  COMMENT: "comment",
  ALARM_CODE: "alarm_code",
};

export const ASSESSMENT_TYPES = {
  cdd: "cdd",
  periodic_odd: "periodic_odd",
  triggerbased_odd: "triggerbased_odd",
};
export const RISK_TYPES = {
  low: "low",
  standard: "standard",
  increased: "increased",
  high: "high",
};

export const FormSections = {
  SECTION_ASSESSMENT_INFO: "section_assessment_info",
  SECTION_CUSTOMER_INFORMATION: "section_customer_information",
  SECTION_MULTIPLE_UNIT_CUSTOMER: "section_multiple_unit_customer",
  SECTION_REPRESENTATIVES: "section_representatives",
  SECTION_PURPOSE: "section_purpose",
  SECTION_ORIGIN_FUNDS: "section_origin_funds",
  SECTION_OWNERSHIP: "section_ownership",
  SECTION_BENEFICIARIES: "section_beneficiaries",
  SECTION_PEP: "section_pep",
  SECTION_EVENTS: "section_events",
  SECTION_MEDIA: "section_media",
  SECTION_EXTRA_INFO: "section_extra_info",
  SECTION_MT: "section_mt",
  SECTION_CONCLUSION: "section_conclusion",
  DEFAULT_NO_SECTION: "last_section",
  SECTION_INTERNATIONAL_TRANSACTION: "section_international_transaction",
  SECTION_HIGH_RISK: "section_high_risk",
  SECTION_SANCTION: "section_sanction",
};

export const ImageConstants = {
  IMAGE_MAX_EXPORTED_WIDTH: 700.0,
  IMAGE_TYPE_JPEG: "image/jpeg",
  IMAGE_TYPE_PNG: "image/png",
  IMAGE_BASE64_PREFIX_JPEG: "data:image/jpeg;base64,",
  IMAGE_BASE64_PREFIX_PNG: "data:image/png;base64,",
};
