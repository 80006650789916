import {
  ChangesSinceLastAssessmentV1,
  ChoiceWithCommentV1,
  IdentificationMethodV1,
  SingleChoiceV1,
} from "../_Common.model";
import { OPTION_NO, OPTION_YES } from "../../_models/_Constants";

export const commonService_Update_ChoiceWithComment = (
  status: ChoiceWithCommentV1,
  payload: { choice: string | undefined; comment: string | undefined },
): ChoiceWithCommentV1 => {
  let result: ChoiceWithCommentV1;
  result = {
    ...status,
    choice: payload.choice,
    comment: payload.comment,
  };
  return result;
};

export const commonService_Update_IdentificationMethod = (
  status: IdentificationMethodV1,
  payload: { choices: string[] | undefined; comment: string | undefined },
): ChangesSinceLastAssessmentV1 => {
  let result: IdentificationMethodV1;
  result = {
    ...status,
    choices: payload.choices,
    comment: payload.comment,
  };
  return result;
};

export const commonService_Update_SingleChoice = (
  choice: string | undefined,
): SingleChoiceV1 => {
  let affirmative: boolean = undefined;
  if (choice && OPTION_YES === choice) {
    affirmative = true;
  }
  if (choice && OPTION_NO === choice) {
    affirmative = false;
  }

  let result: SingleChoiceV1;
  result = {
    choice: choice,
    isAffirmative: affirmative,
  };
  return result;
};

export const commonService_UpdateChanges_LastAssessment = (
  status: ChangesSinceLastAssessmentV1,
  payload: { choice: string; comment: string | undefined },
): ChangesSinceLastAssessmentV1 => {
  let result: ChangesSinceLastAssessmentV1;
  result = {
    ...status,
    anyChanges: payload.choice,
    comment: payload.comment,
  };
  return result;
};

export const commonService_UpdateRisk_LastAssessment = (
  status: ChangesSinceLastAssessmentV1,
  payload: { choice: string | undefined; comment: string | undefined },
): ChangesSinceLastAssessmentV1 => {
  let result: ChangesSinceLastAssessmentV1;
  result = {
    ...status,
    riskIdentified: payload.choice,
    riskIdentifiedComment: payload.comment,
  };
  return result;
};
