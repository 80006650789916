import { SanctionV1 } from "../_EnterpriseCommon.model";
import { commonService_Update_ChoiceWithComment } from "./EnterpriseCommon.service";

export const sanction_updateChoiceComment = (
  status: SanctionV1,
  payload: { choice: string; comment: string | undefined },
): SanctionV1 => {
  let choice = commonService_Update_ChoiceWithComment(status?.choice, payload);
  let result: SanctionV1;
  result = {
    ...status,
    choice: choice,
  };
  return result;
};
