import { ImageConstants } from "../_models/_Constants";
import {
  FormImageV1,
  initFormImageV1,
} from "../_domain-model/FormImageV1.model";
import { arrayIsEmpty } from "./ArrayUtils";

export interface ImageSize {
  width: number;
  height: number;
}

const getImageSize = (item: FormImageV1): ImageSize => {
  let width = 0;
  if (item.width && item.width > 0) {
    width = item.width;
  } else {
    width = 550;
  }

  let height = 0;
  if (item.height && item.height > 0) {
    height = item.height;
  }

  return {
    height: height,
    width: width,
  };
};

const getImageWidth = (item: FormImageV1): number => {
  let width = 0;
  if (item.width && item.width > 0) {
    width = item.width;
  }

  if (width > 500 || width <= 0) {
    width = 500;
  }

  return width;
};

const doLoadImage = async (image: FormImageV1) => {
  let blob = doGetImageBlob(image.metadata.type, image.image);
  let img = new Image();
  img.src = blob;
  await img.decode();
  return { width: img.width, height: img.height };
};

const setImageSizes = async (images: FormImageV1[]): Promise<FormImageV1[]> => {
  if (arrayIsEmpty(images)) {
    return images;
  }

  let result: FormImageV1[] = [];
  const maxWidth = ImageConstants.IMAGE_MAX_EXPORTED_WIDTH;

  for (let i = 0; i < images.length; i++) {
    let image = images[i];

    let img = await doLoadImage(image);
    let height = 0,
      width = 0;
    if (img.width <= maxWidth) {
      height = img.height;
      width = img.width;
    } else {
      let ratio = parseFloat(img.width + ".01") / maxWidth;
      width = maxWidth;
      height = img.height / ratio;
    }

    height = parseInt("" + height);
    width = parseInt("" + width);
    let copy = {
      ...image,
      height: height,
      width: width,
    };
    result.push(copy);
  }

  return result;
};

const filterImage = (images: FormImageV1[], guiId: string | undefined) => {
  const queue = new Array<FormImageV1>();
  images.forEach((item) => {
    if (item.guiId !== guiId) {
      queue.push(item);
    }
  });
  return queue;
};

const toByteArray = async (
  myFile: File | null | undefined,
  section: string,
  callback: (result: FormImageV1) => void,
) => {
  if (myFile) {
    const reader = new FileReader();
    reader.readAsDataURL(myFile);

    reader.onload = function () {
      let content = reader.result;
      if (content !== null) {
        content = content.toString();
        if (myFile.type && myFile.type === ImageConstants.IMAGE_TYPE_JPEG) {
          content = content.replace(
            ImageConstants.IMAGE_BASE64_PREFIX_JPEG,
            "",
          );
        } else {
          content = content.replace(ImageConstants.IMAGE_BASE64_PREFIX_PNG, "");
        }

        const id = "img_" + new Date().getTime();
        let img = initFormImageV1(
          id,
          myFile.name,
          myFile.type,
          section,
          content,
        );
        callback(img);
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
};

const pastedBlobToByteArray = async (
  myFile: Blob | null | undefined,
  section: string,
  callback: (result: FormImageV1) => void,
) => {
  if (myFile) {
    const reader = new FileReader();
    reader.readAsDataURL(myFile);

    reader.onload = function () {
      let content = reader.result;
      if (content !== null) {
        content = content.toString();
        if (myFile.type && myFile.type === ImageConstants.IMAGE_TYPE_JPEG) {
          content = content.replace(
            ImageConstants.IMAGE_BASE64_PREFIX_JPEG,
            "",
          );
        } else {
          content = content.replace(ImageConstants.IMAGE_BASE64_PREFIX_PNG, "");
        }

        const id = "img_" + new Date().getTime();
        let img = initFormImageV1(id, id, myFile.type, section, content);
        callback(img);
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
};

const doGetImageBlob = (
  type: string,
  content: string | ArrayBuffer | undefined | null,
) => {
  if (!content) {
    return undefined;
  }

  let result: string;

  if (type) {
    if (type === ImageConstants.IMAGE_TYPE_JPEG) {
      result = ImageConstants.IMAGE_BASE64_PREFIX_JPEG + content;
      return result;
    }
  }
  result = ImageConstants.IMAGE_BASE64_PREFIX_PNG + content;

  return result;
};

const getImageBlob = (item: FormImageV1): string | undefined => {
  return doGetImageBlob(item.metadata.type, item.image);
};

const getImageBlob2 = (item: FormImageV1): string | undefined => {
  return doGetImageBlob(item.metadata.type, item.content);
};

const ImageUtils = {
  getImageSize: getImageSize,
  getImageWidth: getImageWidth,
  setImageSizes: setImageSizes,
  filterImage: filterImage,
  pastedBlobToByteArray: pastedBlobToByteArray,
  toByteArray: toByteArray,
  getImageBlob: getImageBlob,
  getImageBlob2: getImageBlob2,
};

export default ImageUtils;
