import { ConclusionV1 } from "../_EnterpriseCommon.model";
import { commonService_Update_ChoiceWithComment } from "./EnterpriseCommon.service";

export const extraConclusion_updateTiltakComment = (
  status: ConclusionV1,
  payload: { choice: string; comment: string | undefined },
): ConclusionV1 => {
  let tiltak = commonService_Update_ChoiceWithComment(status?.tiltak, payload);
  let result: ConclusionV1;
  result = {
    ...status,
    tiltak: tiltak,
  };
  return result;
};

export const extraConclusion_updateComment = (
  status: ConclusionV1,
  payload: string | undefined,
): ConclusionV1 => {
  let result: ConclusionV1;

  result = {
    ...status,
    comment: payload,
  };
  return result;
};
