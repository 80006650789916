import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LANGUAGES } from "../_models/_Constants";
import { UserSetting } from "../_models/UserSettings.model";
import { RootState } from "./AppStoreRegistryStore";

const initialState: UserSetting = {
  language: LANGUAGES.NORWEGIAN_BOKMAAL,
};

export const UserSettingStore = createSlice({
  name: "UserSettingStore",
  initialState,
  reducers: {
    changeLanguageAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      switch (action.payload) {
        case LANGUAGES.ENGLISH:
          state.language = action.payload;
          break;

        default:
          state.language = LANGUAGES.NORWEGIAN_BOKMAAL;
          break;
      }
    },
  },
});

export const { changeLanguageAction } = UserSettingStore.actions;

export const selectUserLanguageState = (state: RootState) =>
  state.UserSettingStore.language;

export default UserSettingStore.reducer;
