import { ASSESSMENT_TYPES } from "../../_models/_Constants";

export const serviceGetValidAssessmentType = (
  payload: string | null | undefined,
): string => {
  if (!payload || payload === null) {
    return undefined;
  }
  switch (payload) {
    case ASSESSMENT_TYPES.cdd:
    case ASSESSMENT_TYPES.triggerbased_odd:
      return payload;

    default:
      return ASSESSMENT_TYPES.periodic_odd;
  }
};
