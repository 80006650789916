import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppConfig } from "../_models/AppConfig.model";
import { DEPLOY_MODES } from "../_models/_Constants";
import { RootState } from "./AppStoreRegistryStore";

const initialState: AppConfig = {
  deployMode: DEPLOY_MODES.DEPLOYED,
  isMock: false,
};

export const AppConfigurationStore = createSlice({
  name: "AppConfigurationStore",
  initialState,
  reducers: {
    initConfigAction: (state, action: PayloadAction<string>) => {
      //   console.log("initConfigAction: ", action.payload);
      switch (action.payload) {
        case DEPLOY_MODES.LOCAL:
        case DEPLOY_MODES.MOCK:
          state.deployMode = action.payload;
          break;
        default:
          state.deployMode = DEPLOY_MODES.DEPLOYED;
          break;
      }
    },
  },
});

export const { initConfigAction } = AppConfigurationStore.actions;

export const selectAppConfigState = (state: RootState) =>
  state.AppConfigurationStore;

export default AppConfigurationStore.reducer;
