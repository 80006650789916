import { RISK_TYPES } from "../../_models/_Constants";

export const serviceGetValidRiskType = (
  payload: string | null | undefined,
): string => {
  if (!payload || payload === null) {
    return undefined;
  }
  switch (payload) {
    case RISK_TYPES.standard:
    case RISK_TYPES.increased:
    case RISK_TYPES.high:
      return payload;

    default:
      return RISK_TYPES.low;
  }
};

export const serviceIsHighRisk = (
  payload: string | null | undefined,
): boolean | undefined => {
  if (!payload || payload === null) {
    return undefined;
  }
  switch (payload) {
    case RISK_TYPES.increased:
    case RISK_TYPES.high:
      return true;

    default:
      return false;
  }
};
