import loadable from "@loadable/component";
import "react-quill/dist/quill.snow.css";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import { initConfigAction } from "./AppStore/AppConfigurationStore";
import { useAppDispatch } from "./AppStore/AppStoreHooks";
import { API_URL, REDIRECT_URL } from "./_models/ApiConfig.model";
import { ASSESSMENT_TYPES, DEPLOY_MODES } from "./_models/_Constants";
import CheckState from "./components/CheckState/CheckState";
import LanguageHandler from "./features/LanguageHandler/LanguageHandler";
import { clearAll } from "./api/AuthStorage.service";

const mapEnvToDeployMode = (nodeEnv: string | undefined): string => {
  if (!nodeEnv) {
    return DEPLOY_MODES.DEPLOYED;
  }

  switch (nodeEnv) {
    case "development":
      return DEPLOY_MODES.MOCK;

    case "localhost":
      return DEPLOY_MODES.LOCAL;
  }
  return DEPLOY_MODES.DEPLOYED;
};

const HomePage = loadable(() => import("./features/HomePage/HomePage"));
const AuthContainer = loadable(() => import("./features/Auth/AuthContainer"));
const ContainerCopyForm = loadable(
  () => import("./features/ContainerCopyForm/ContainerCopyForm"),
);
const ContainerCdd = loadable(
  () => import("./features/ContainerCdd/ContainerCdd"),
);
const NotFoundPage = loadable(
  () => import("./features/NotFoundPage/NotFoundPage"),
);
const ContainerPeriodicOdd = loadable(
  () => import("./features/ContainerPeriodicOdd/ContainerPeriodicOdd"),
);
const ContainerTriggerbasedOdd = loadable(
  () => import("./features/ContainerTriggerbasedOdd/ContainerTriggerbasedOdd"),
);

const shouldForceClearLocalStorageAuth = () => {
  const url = new URL(window.location.href);
  const shouldForceClearLocalStorage = url.searchParams.get("c") === "1";

  return shouldForceClearLocalStorage;
};

function MainApp() {
  if (shouldForceClearLocalStorageAuth()) {
    clearAll();
  }

  const dispatch = useAppDispatch();
  const deployMode = mapEnvToDeployMode(process.env.REACT_APP_DEPLOY_MODE);
  console.log(process.env.REACT_APP_DEPLOY_MODE + " - mapped: " + deployMode);
  console.log("REDIRECT_URL: ", REDIRECT_URL);
  console.log(API_URL);
  dispatch(initConfigAction(deployMode));

  return (
    <div className="App">
      <LanguageHandler />
      <CheckState />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth" element={<AuthContainer />} />
        <Route path="/Copy" element={<ContainerCopyForm />} />
        <Route
          path={ASSESSMENT_TYPES.periodic_odd}
          element={<ContainerPeriodicOdd />}
        />
        <Route
          path={ASSESSMENT_TYPES.triggerbased_odd}
          element={<ContainerTriggerbasedOdd />}
        />
        <Route path={ASSESSMENT_TYPES.cdd} element={<ContainerCdd />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default MainApp;
