import { OriginOfFundsV1 } from "../_EnterpriseCommon.model";
import {
  commonService_Update_IdentificationMethod,
  commonService_UpdateChanges_LastAssessment,
  commonService_UpdateRisk_LastAssessment,
} from "./EnterpriseCommon.service";

export const originFundsService_updateIdentification = (
  status: OriginOfFundsV1,
  payload: { choices: string[] | undefined; comment: string | undefined },
): OriginOfFundsV1 => {
  let item = commonService_Update_IdentificationMethod(
    status.identificationMethod,
    payload,
  );
  let result: OriginOfFundsV1;
  result = {
    ...status,
    identificationMethod: item,
  };
  return result;
};

export const originFundsService_updateChanges = (
  status: OriginOfFundsV1,
  payload: { choice: string; comment: string | undefined },
): OriginOfFundsV1 => {
  let changes = commonService_UpdateChanges_LastAssessment(
    status.changes,
    payload,
  );
  let result: OriginOfFundsV1;
  result = {
    ...status,
    changes: changes,
  };
  return result;
};

export const originFundsService_updateRisk = (
  status: OriginOfFundsV1,
  payload: { choice: string; comment: string | undefined },
): OriginOfFundsV1 => {
  // let status = commonService_UpdateRisk_LastAssessment(state.assessment?.originOfFunds?.changes, payload);
  // state.assessment.originOfFunds.changes = status;

  let changes = commonService_UpdateRisk_LastAssessment(
    status.changes,
    payload,
  );
  let result: OriginOfFundsV1;
  result = {
    ...status,
    changes: changes,
  };
  return result;
};

export const originFundsService_updateComment = (
  status: OriginOfFundsV1,
  payload: string | undefined,
): OriginOfFundsV1 => {
  let result: OriginOfFundsV1;
  result = {
    ...status,
    comment: payload,
  };
  return result;
};
