import { MultipleUnitCustomerV1 } from "../_EnterpriseCommon.model";

export const multiUnitService_updateMultiUnit = (
  status: MultipleUnitCustomerV1,
  payload: { choice: string; comment: string | undefined },
): MultipleUnitCustomerV1 => {
  let result: MultipleUnitCustomerV1;
  result = {
    ...status,
    multipleUnitChoice: payload.choice,
    businessUnit: payload.comment,
  };
  return result;
};

export const multiUnitService_updateOtherBusiness = (
  status: MultipleUnitCustomerV1,
  payload: { choices: string[] | undefined; comment: string | undefined },
): MultipleUnitCustomerV1 => {
  let result: MultipleUnitCustomerV1;
  result = {
    ...status,
    otherBusinessComment: payload.comment,
    otherBusinessChoice: payload.choices,
  };
  return result;
};
