import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { RootState } from "./AppStoreRegistryStore";
import {
  FormContributorV1,
  UserCommentStateV1,
  UserCommentV1,
} from "../_domain-model/UserComment.model";
import { arrayIsEmpty } from "../Utils/ArrayUtils";

const initialState: UserCommentStateV1 = {
  createdBy: undefined,
  comments: undefined,
};

const init = (
  state: WritableDraft<UserCommentStateV1>,
  payload: string | null | undefined,
) => {
  state.createdBy = undefined;
  state.comments = [];
};

const initCreatedByFromState = (
  state: WritableDraft<UserCommentStateV1>,
  payload: FormContributorV1 | null | undefined,
) => {
  state.createdBy = payload;
};

const initUserCommentFromState = (
  state: WritableDraft<UserCommentStateV1>,
  payload: UserCommentV1[] | null | undefined,
) => {
  if (arrayIsEmpty(payload)) {
    state.comments = [];
  } else {
    state.comments = payload;
  }
};

const removeComment = (
  state: WritableDraft<UserCommentStateV1>,
  payload: string | null | undefined,
) => {
  let comments: UserCommentV1[] = [];
  state.comments.forEach((item) => {
    if (item.commentId !== payload) {
      comments.push(item);
    }
  });

  state.comments = comments;
};

const addComment = (
  state: WritableDraft<UserCommentStateV1>,
  payload: UserCommentV1 | null | undefined,
) => {
  if (!payload) {
    return;
  }
  let comments = [...state.comments];
  comments.unshift(payload);
  state.comments = comments;
};

export const UserCommentStore = createSlice({
  name: "UserCommentStore",
  initialState,
  reducers: {
    initUserCommentAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      init(state, action.payload);
    },
    initUserCommentFromStateAction: (
      state,
      action: PayloadAction<UserCommentV1[] | null | undefined>,
    ) => {
      initUserCommentFromState(state, action.payload);
    },
    initCreatedByFromStateAction: (
      state,
      action: PayloadAction<FormContributorV1 | null | undefined>,
    ) => {
      initCreatedByFromState(state, action.payload);
    },
    removeCommentAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      removeComment(state, action.payload);
    },
    addCommentAction: (
      state,
      action: PayloadAction<UserCommentV1 | null | undefined>,
    ) => {
      addComment(state, action.payload);
    },
  },
});

export const {
  initUserCommentAction,
  initUserCommentFromStateAction,
  initCreatedByFromStateAction,
  removeCommentAction,
  addCommentAction,
} = UserCommentStore.actions;

export const selectAssessmentCreatedByState = (state: RootState) =>
  state.UserCommentStore?.createdBy;
export const selectUserCommentListState = (state: RootState) =>
  state.UserCommentStore?.comments;

export default UserCommentStore.reducer;
