import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  initAssessmentAction,
  selectAssessmentTypeState,
} from "../../AppStore/CustomerAssessmentStore";
import { useAppDispatch } from "../../AppStore/AppStoreHooks";
import {
  initCreatedByFromStateAction,
  initUserCommentAction,
  selectAssessmentCreatedByState,
} from "../../AppStore/UserCommentStore";
import { FormContributorV1 } from "../../_domain-model/UserComment.model";
import { getEmployeeId, getEmployeeName } from "../../api/AuthStorage.service";
import {
  initUserImageAction,
  selectUploadedImagesState,
} from "../../AppStore/UserImageStore";
import { arrayIsEmpty } from "../../Utils/ArrayUtils";
import {
  initUserSignaturesAction,
  selectUserSignaturesState,
} from "../../AppStore/UserSignatureStore";

type Props = {};

const CheckState: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const assessmentType = useSelector(selectAssessmentTypeState);
  const userComment = useSelector(selectAssessmentCreatedByState);
  const uploadedImages = useSelector(selectUploadedImagesState);
  const userSignatures = useSelector(selectUserSignaturesState);

  if (!assessmentType) {
    dispatch(initAssessmentAction("initAssessmentAction"));
    // dispatch(initUserImageAction('initUserImageAction'));
  }

  if (!userComment || userComment === null) {
    dispatch(initUserCommentAction("initUserCommentAction"));

    let createdBy: FormContributorV1;
    createdBy = {
      userId: getEmployeeId(),
      userName: getEmployeeName(),
    };
    dispatch(initCreatedByFromStateAction(createdBy));
  }

  if (!userSignatures || userSignatures === null) {
    dispatch(initUserSignaturesAction("initUserSignaturesAction"));
  }

  useEffect(() => {
    if (arrayIsEmpty(uploadedImages)) {
      dispatch(initUserImageAction("initUserImageAction"));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

export default CheckState;
