import { InternationalTransactionV1 } from "../_EnterpriseCommon.model";
import { commonService_Update_ChoiceWithComment } from "./EnterpriseCommon.service";

export const international_transaction_updateChoiceComment = (
  status: InternationalTransactionV1,
  payload: { choice: string; comment: string | undefined },
): InternationalTransactionV1 => {
  let choice = commonService_Update_ChoiceWithComment(status?.choice, payload);
  let result: InternationalTransactionV1;
  result = {
    ...status,
    choice: choice,
  };
  return result;
};
