import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./AppStoreRegistryStore";
import ICustomer, {
  CustomerSearch,
} from "../_domain-model/CustomerSearch.model";
import { SEARCH_STATES } from "../_models/_Constants";
import { WritableDraft } from "immer/dist/internal";
import { getCleanedOrgNo, stringValidateSsnOrgNr } from "../Utils/StringUtils";
import { CompanyProfileFlat } from "../_domain-model/CompanyProfile.model";

const initialState: CustomerSearch = {
  searchInProgress: false,
  state: SEARCH_STATES.NOT_SEARCHED,
};

const validateSsnOrOrgNr = (
  state: WritableDraft<CustomerSearch>,
  payload: string | undefined,
) => {
  //state.search = payload;
  let cleanedValue = getCleanedOrgNo(payload);

  let valid = stringValidateSsnOrgNr(cleanedValue);
  state.search = valid.value;
  if (valid.valid) {
    state.state = SEARCH_STATES.VALID;
    state.formatted = valid.formatted;
  } else {
    state.state = SEARCH_STATES.NOT_VALID;
    state.formatted = valid.value;
  }
};

const setCustomer = (
  state: WritableDraft<CustomerSearch>,
  payload: ICustomer | undefined,
) => {
  state.customer = payload;
  state.profile = undefined;
};

const setCompanyProfile = (
  state: WritableDraft<CustomerSearch>,
  payload: CompanyProfileFlat | undefined,
) => {
  state.profile = payload;
};

export const CustomerSearchStore = createSlice({
  name: "CustomerSearchStore",
  initialState,
  reducers: {
    resetSearchAction: (state, action: PayloadAction<string | undefined>) => {
      state.searchInProgress = false;
      state.search = undefined;
      state.formatted = undefined;
      state.state = SEARCH_STATES.NOT_SEARCHED;
      state.customer = undefined;
      state.profile = undefined;
    },
    validateSsnOrOrgNrAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      validateSsnOrOrgNr(state, action.payload);
    },
    setCustomerAction: (
      state,
      action: PayloadAction<ICustomer | undefined>,
    ) => {
      setCustomer(state, action.payload);
    },
    setCompanyProfileAction: (
      state,
      action: PayloadAction<CompanyProfileFlat | undefined>,
    ) => {
      setCompanyProfile(state, action.payload);
    },
    activateSearchInProgressAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.searchInProgress = true;
    },
    deactivateSearchInProgressAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.searchInProgress = false;
    },
  },
});

export const {
  resetSearchAction,
  validateSsnOrOrgNrAction,
  setCustomerAction,
  setCompanyProfileAction,
  activateSearchInProgressAction,
  deactivateSearchInProgressAction,
} = CustomerSearchStore.actions;

export const selectCustomerSearchState = (state: RootState) =>
  state.CustomerSearchStore;

export default CustomerSearchStore.reducer;
