import { PepV1 } from "../_EnterpriseCommon.model";
import { stringToBoolean } from "../../Utils/StringUtils";
import {
  commonService_Update_ChoiceWithComment,
  commonService_Update_IdentificationMethod,
  commonService_Update_SingleChoice,
} from "./EnterpriseCommon.service";
import { arrayIsEmpty } from "../../Utils/ArrayUtils";

export const pepService_updateChoiceComment = (
  status: PepV1,
  payload: { choice: string; comment: string | undefined },
): PepV1 => {
  let result: PepV1;
  let hasPep = stringToBoolean(payload.choice);
  let choice = commonService_Update_ChoiceWithComment(status?.choice, payload);

  result = {
    ...status,
    choice: choice,
    hasPep: hasPep,
  };

  return result;
};

export const pepService_updateDeclarationDate = (
  status: PepV1,
  payload: string | undefined,
): PepV1 => {
  let result: PepV1;
  result = {
    ...status,
    receivedDeclaration: payload,
  };
  console.log(result);
  return result;
};

export const pepService_updateIdentification = (
  status: PepV1,
  payload: { choices: string[] | undefined; comment: string | undefined },
): PepV1 => {
  let item = commonService_Update_IdentificationMethod(
    status.identificationMethod,
    payload,
  );
  let result: PepV1;
  result = {
    ...status,
    identificationMethod: item,
  };
  return result;
};

export const pepService_updateConfirmation = (
  status: PepV1,
  payload: { choices: string[] | undefined },
): PepV1 => {
  let choice: string;

  if (!arrayIsEmpty(payload?.choices)) {
    choice = payload.choices[0];
  }

  let item = commonService_Update_SingleChoice(choice);
  let result: PepV1;
  result = {
    ...status,
    pepAskedChoice: item,
  };
  return result;
};
