import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SaveOperation } from "../_models/AutoSaving.model";

import { RootState } from "./AppStoreRegistryStore";
import { OPERATION_STATE } from "../_models/_Constants";

const initialState: SaveOperation = {
  operationState: OPERATION_STATE.NOT_IN_PROGRESS,
};

export const OperationStore = createSlice({
  name: "UserSettingStore",
  initialState,
  reducers: {
    resetSaveOperationAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.operationState = OPERATION_STATE.NOT_IN_PROGRESS;
    },
    activateSaveInProgressAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.operationState = OPERATION_STATE.IN_PROGRESS;
    },
    saveCompletedSuccessAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.operationState = OPERATION_STATE.COMPLETED_SUCCESS;
    },
    saveCompletedErrorAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.operationState = OPERATION_STATE.COMPLETED_ERROR;
    },
  },
});

export const {
  resetSaveOperationAction,
  activateSaveInProgressAction,
  saveCompletedSuccessAction,
  saveCompletedErrorAction,
} = OperationStore.actions;

export const selectSaveOperationState = (state: RootState) =>
  state.OperationStore.operationState;

export default OperationStore.reducer;
