import {
  AssessmentStateV1,
  CustomerAssessmentV1,
} from "../CustomerAssessment.model";

const doUpdateRepresentatives = (payload: AssessmentStateV1) => {
  if (
    !payload?.assessment?.representatives ||
    payload?.assessment?.representatives === null
  ) {
    payload.assessment.representatives = {
      changes: {},
      identificationMethod: {},
    };
  }

  if (
    !payload?.assessment?.representatives?.identificationMethod ||
    payload?.assessment?.representatives?.identificationMethod === null
  ) {
    payload.assessment.representatives = {
      ...payload?.assessment?.representatives,
      identificationMethod: {},
    };
  }
};

const doUpdatePep = (payload: AssessmentStateV1) => {
  if (!payload?.assessment?.pep || payload?.assessment?.pep === null) {
    payload.assessment.pep = {
      choice: {},
      pepAskedChoice: {},
      identificationMethod: {},
    };
  }

  if (
    !payload?.assessment?.pep?.identificationMethod ||
    payload?.assessment?.pep?.identificationMethod === null
  ) {
    payload.assessment.pep = {
      ...payload?.assessment?.pep,
      identificationMethod: {},
    };
  }

  if (
    !payload?.assessment?.pep?.pepAskedChoice ||
    payload?.assessment?.pep?.pepAskedChoice === null
  ) {
    payload.assessment.pep = {
      ...payload?.assessment?.pep,
      pepAskedChoice: {},
    };
  }
};

const doUpdateRemark = (payload: AssessmentStateV1) => {
  if (!payload?.assessment?.remark || payload?.assessment?.remark === null) {
    payload.assessment.remark = {
      choice: {},
    };
  }
};

// this ensures fields which may not exist in saved state
export const serviceCleanSavedAssessment = (
  payload: AssessmentStateV1 | null | undefined,
): AssessmentStateV1 | undefined => {
  if (!payload || payload === null) {
    return undefined;
  }
  doUpdateRemark(payload);
  doUpdateRepresentatives(payload);
  doUpdatePep(payload);

  return payload;
};

export const serviceInitAssessment = (): CustomerAssessmentV1 => {
  let assessment: CustomerAssessmentV1;
  assessment = {
    remark: {
      choice: {},
    },
    customerInfo: {
      changes: {},
      identificationMethod: {},
    },
    multiUnitCustomer: {},
    representatives: {
      changes: {},
      identificationMethod: {},
    },
    purpose: {
      changes: {},
      identificationMethod: {},
    },
    originOfFunds: {
      changes: {},
      identificationMethod: {},
    },
    ownership: {
      changes: {},
      identificationMethod: {},
    },
    beneficiary: {
      changes: {},
      identificationMethod: {},
      zenitIdentification: {},
    },
    pep: {
      choice: {},
      identificationMethod: {},
      pepAskedChoice: {},
    },
    eventStatus: {
      alarmConfirmation: undefined,
      foundAlarm: undefined,
      events: [],
    },
    media: {
      choice: {},
    },
    extraInfo: {
      info: {},
      tiltak: {},
      canEstablish: {},
    },
    conclusion: {
      tiltak: {},
    },
    mtMessage: {
      choice: {},
    },
    originOfWealth: {},
  };

  return assessment;
};

export const cloneAssessment = (
  assessment: CustomerAssessmentV1 | null | undefined,
): CustomerAssessmentV1 | undefined => {
  if (!assessment || assessment === null) {
    return undefined;
  }

  const str = JSON.stringify(assessment);
  const result = JSON.parse(str) as CustomerAssessmentV1;
  return result;
};
