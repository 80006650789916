import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locales/en.json";
import nb from "../locales/nb.json";
// import periodicOddEn from "../locales/periodic_odd_en.json";
// import periodicOddNo from "../locales/periodic_odd_nb.json";

const i18nLoc = i18n.use(initReactI18next);

// const type 18nConfig = {
//   defaultLocale: 'en',
//   locales: ['en', 'de'],
// };

// const resources = {
//   en: {
//     translation: en,
//   },
//   nb: {
//     translation: nb,
//   }
// };

// i18nLoc.init({
//   lng: 'nb',

//   // lng: "nb",
//   resources,
//   // keySeparator: '.', // we do not use keys in form messages.welcome
//   // fallbackLng: 'nb',
//   defaultNS: 'translation',
// });

i18nLoc.init({
  lng: "nb",
  resources: { en, nb },
});

// i18nLoc.addResources("en", "periodicOdd", { periodicOddEn });
// i18nLoc.addResources("nb", "periodicOdd", { periodicOddNo });

export const changeI18nLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
};

export default i18n;
