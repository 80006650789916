import { MediaV1 } from "../_EnterpriseCommon.model";
import { commonService_Update_ChoiceWithComment } from "./EnterpriseCommon.service";

export const mediaService_updateChoiceComment = (
  status: MediaV1,
  payload: { choice: string; comment: string | undefined },
): MediaV1 => {
  let choice = commonService_Update_ChoiceWithComment(status?.choice, payload);
  let result: MediaV1;
  result = {
    ...status,
    choice: choice,
  };
  return result;
};

export const mediaService_updateDate = (
  status: MediaV1,
  payload: string | undefined,
): MediaV1 => {
  let result: MediaV1;
  result = {
    ...status,
    mediaDate: payload,
  };
  return result;
};
