import { TokenResponse, UserInfo } from "../_auth-model/auth-models";
import jwt_decode from "jwt-decode";

export const clearAll = (): void => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(`employee_id`);
    window.localStorage.removeItem("employee_name");
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem(`token_expires`);
    window.localStorage.removeItem("code_verifier");
  }
};

export const setUserInfo = (userTokens: UserInfo): void => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(`employee_id`, userTokens.abnr);
    window.localStorage.setItem("employee_name", userTokens.name);
  }
};

export const setEmployeeId = (userId: string): void => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(`employee_id`, userId);
  }
};

export const setEmployeeName = (userName: string): void => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(`employee_name`, userName);
  }
};

interface TokenExpires {
  exp: number;
}

export const setToken = (token: TokenResponse): void => {
  if (typeof window !== "undefined") {
    if (token !== null && token !== undefined) {
      console.log("access_token: ", token.id_token);
      if (token.id_token !== null && token.id_token !== undefined) {
        window.localStorage.setItem("access_token", token.id_token);
      }
      if (token.refresh_token !== null && token.refresh_token !== undefined) {
        window.localStorage.setItem("refresh_token", token.refresh_token);
      }

      if (token.expires_in !== null && token.expires_in !== undefined) {
        const decodedToken = jwt_decode(token.id_token) as TokenExpires;
        window.localStorage.setItem(
          `token_expires`,
          `` + decodedToken.exp * 1000,
        );
      }
    }
  }
};

export const setCodeVerifier = (codeVerifier: string): void => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(`code_verifier`, codeVerifier);
  }
};

export const getAccessToken = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem("access_token");
  }
};

export const getCodeVerifier = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem("code_verifier");
  }
};

export const getEmployeeId = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem("employee_id");
  }
};

export const getEmployeeName = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem("employee_name");
  }
};
