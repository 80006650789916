import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { changeI18nLanguage } from "../../i18n/languages";

import { selectUserLanguageState } from "../../AppStore/UserSettingStore";

type Props = {};

const LanguageHandler: React.FC<Props> = () => {
  const userLanguage = useSelector(selectUserLanguageState);

  useEffect(() => {
    changeI18nLanguage(userLanguage);
  }, [userLanguage]);

  return <></>;
};

export default LanguageHandler;
