import {
  TransactionAlarmV1,
  TriggerEventsStatusV1,
  TriggerEventV1,
} from "../_EnterpriseCommon.model";
import {
  arrayFindIndex,
  arrayIsEmpty,
  arrayIsNotEmpty,
} from "../../Utils/ArrayUtils";
import {
  TRANSACTION_ALARMS,
  TRANSACTION_FIELDS,
} from "../../_models/_Constants";
import {
  stringToBoolean,
  stringValidateYesNoOption,
} from "../../Utils/StringUtils";

export const serviceIsAlarmFound = (
  payload: string | null | undefined,
): { found: boolean | undefined; confirmation: string | undefined } => {
  return {
    found: stringToBoolean(payload),
    confirmation: stringValidateYesNoOption(payload),
  };
};

export const eventService_GetCommentAlarmEvent = (
  status: TriggerEventsStatusV1 | undefined,
  alarmCode: string,
): string | undefined => {
  if (!status || !status.events) {
    return undefined;
  }
  let result: string | undefined;
  status.events.forEach((item, idx) => {
    if (item.alarmCode === alarmCode) {
      result = item.comment;
    }
  });

  return result;
};

export const eventService_updateAlarmEventField = (
  status: TriggerEventsStatusV1,
  payload: { fieldName: string; alarmCode: string; value: string | undefined },
): TriggerEventsStatusV1 => {
  if (payload.alarmCode === TRANSACTION_ALARMS.TRANSAKSJONSALARM) {
    let item: TransactionAlarmV1;
    if (payload.fieldName === TRANSACTION_FIELDS.ALARM_CODE) {
      item = {
        ...status.transactionAlarm,
        alarmCode: payload.value,
      };
    } else if (payload.fieldName === TRANSACTION_FIELDS.COMMENT) {
      item = {
        ...status.transactionAlarm,
        comment: payload.value,
      };
    }

    if (item === undefined) {
      return status;
    }
    let result: TriggerEventsStatusV1 = {
      ...status,
      transactionAlarm: item,
    };
    return result;
  }

  let index = -1;
  status.events.forEach((item, idx) => {
    if (item.alarmCode === payload.alarmCode) {
      index = idx;
    }
  });

  if (index >= 0) {
    status.events[index] = {
      ...status.events[index],
      comment: payload.value,
    };
  }

  let result: TriggerEventsStatusV1 = {
    ...status,
    events: [...status.events],
  };
  return result;
};

export const eventService_ShowNonTransactionAlarms = (
  status: TriggerEventsStatusV1 | undefined,
): boolean => {
  if (!status) {
    return false;
  }

  if (!status?.selectedValues) {
    return false;
  }

  let ctr = 0;
  status.selectedValues.forEach((s) => {
    if (s !== TRANSACTION_ALARMS.TRANSAKSJONSALARM) {
      ctr++;
    }
  });

  if (ctr > 0) {
    return true;
  }
  return false;
};

export const eventService_ShowAlarmType = (
  alarmType: string,
  status: TriggerEventsStatusV1 | undefined,
): boolean => {
  if (!status) {
    return false;
  }

  const index = arrayFindIndex(status?.selectedValues, alarmType);
  if (index >= 0) {
    return true;
  }
  return false;
};

export const eventServiceUpdate_WhenSelectionChanges = (
  selections: string[] | undefined,
  status: TriggerEventsStatusV1 | undefined,
): TriggerEventsStatusV1 | undefined => {
  if (arrayIsEmpty(selections)) {
    const result: TriggerEventsStatusV1 = {
      ...status,
      selectedValues: [],
    };
    return result;
  }

  let transAlarm: TransactionAlarmV1 = {};
  if (status?.transactionAlarm) {
    transAlarm = {
      ...status.transactionAlarm,
    };
  }
  const indexTransAlarm = arrayFindIndex(
    selections,
    TRANSACTION_ALARMS.TRANSAKSJONSALARM,
  );
  if (indexTransAlarm >= 0 && !transAlarm) {
    transAlarm = {};
  }

  let existingEvents: TriggerEventV1[] = [];
  let events: TriggerEventV1[] = [];
  if (arrayIsNotEmpty(status?.events)) {
    existingEvents = [...status?.events];
  }

  // add events which are not transactions_alarm
  // this bit is to make sure event blocks are added in same order as selected checkboxes
  // for each selected alarm => add copy if exists => else add new
  selections.forEach((s) => {
    if (s !== TRANSACTION_ALARMS.TRANSAKSJONSALARM) {
      let added = false;
      existingEvents.forEach((x) => {
        if (s === x.alarmCode) {
          events.push({
            ...x,
          });
          added = true;
        }
      });

      if (!added) {
        let event: TriggerEventV1 = {
          alarmCode: s,
        };
        events.push(event);
      }
    }
  });

  let result: TriggerEventsStatusV1 = {
    ...status,
    selectedValues: selections,
    transactionAlarm: transAlarm,
    events: events,
  };

  return result;
};
