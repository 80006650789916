import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { RootState } from "./AppStoreRegistryStore";
import {
  FormImageV1,
  UserImageStateV1,
} from "../_domain-model/FormImageV1.model";
import { arrayIsNotEmpty } from "../Utils/ArrayUtils";
import ImageUtils from "../Utils/ImageUtils";

const initialState: UserImageStateV1 = {
  queuedImages: undefined,
  uploadedImages: undefined,
};
const init = (
  state: WritableDraft<UserImageStateV1>,
  payload: string | null | undefined,
) => {
  state.queuedImages = [];
  state.uploadedImages = [];
};

const initImagesFromState = (
  state: WritableDraft<UserImageStateV1>,
  payload: FormImageV1[] | null | undefined,
) => {
  if (arrayIsNotEmpty(payload)) {
    let images: FormImageV1[] = [];
    payload.forEach((item) => {
      let image: FormImageV1;
      image = {
        ...item,
        guiId: item.imageId,
        loading: false,
      };
      images.push(image);
    });
    state.uploadedImages = images;
  }
};

export const UserImageStore = createSlice({
  name: "UserImageStore",
  initialState,
  reducers: {
    initUserImageAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      init(state, action.payload);
    },
    initUserImagesFromStateAction: (
      state,
      action: PayloadAction<FormImageV1[] | null | undefined>,
    ) => {
      initImagesFromState(state, action.payload);
    },
    addUserImageAction: (state, action: PayloadAction<FormImageV1>) => {
      let images = state.uploadedImages;
      images.push(action.payload);
      state.uploadedImages = [...images];
    },
    removeUserImageAction: (state, action: PayloadAction<FormImageV1>) => {
      let images = state.uploadedImages;
      images = ImageUtils.filterImage(images, action.payload.guiId);
      state.uploadedImages = [...images];
    },
  },
});

export const {
  initUserImageAction,
  initUserImagesFromStateAction,
  addUserImageAction,
  removeUserImageAction,
} = UserImageStore.actions;

export const selectUploadedImagesState = (state: RootState) =>
  state.UserImageStore?.uploadedImages;
// export const selectQueuedImagesState = (state: RootState) => state.UserImageStore?.queuedImages;

export default UserImageStore.reducer;
