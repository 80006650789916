export type ImageMetadataV1 = {
  fileName: string | undefined;
  type: string;
  size?: number | undefined;
  lastModified?: number | undefined;
};

export type FormImageV1 = {
  // metadata props
  imageId: string | undefined;
  metadata: ImageMetadataV1 | undefined;

  section: string | undefined;
  created: string | undefined;
  userId: string | undefined;
  userName: string | undefined;

  // image blob
  content: string | ArrayBuffer | null;
  image: string | ArrayBuffer | undefined;

  // gui props
  guiId: string | undefined;
  loading: boolean | undefined;
  uploadSuccess: boolean | undefined;
  uploadError: boolean | undefined;
  height?: number;
  width?: number;
};

export const initFormImageV1 = (
  id: string,
  fileName: string,
  fileType: string,
  section: string,
  content: string,
): FormImageV1 => {
  return {
    imageId: id,
    metadata: {
      type: fileType,
      fileName: fileName,
    },
    // section: FormSections.DEFAULT_NO_SECTION,
    section: section,
    created: undefined,
    userId: undefined,
    userName: undefined,
    content: content,
    image: undefined,
    guiId: id,
    loading: false,
    uploadSuccess: undefined,
    uploadError: undefined,
  };
};

export type UserImageStateV1 = {
  queuedImages: FormImageV1[] | undefined;
  uploadedImages: FormImageV1[] | undefined;
};
