import { HighRiskV1 } from "../_EnterpriseCommon.model";
import { commonService_Update_ChoiceWithComment } from "./EnterpriseCommon.service";

export const high_risk_updateChoiceComment = (
  status: HighRiskV1,
  payload: { choice: string; comment: string | undefined },
): HighRiskV1 => {
  let choice = commonService_Update_ChoiceWithComment(status?.choice, payload);
  let result: HighRiskV1;
  result = {
    ...status,
    choice: choice,
  };
  return result;
};
